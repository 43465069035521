import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      permissions: [],
    },
  },
  {
    path: '/stations',
    name: 'stations',
    component: () => import('@/views/Stations.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['meter-station-list'],
    },
  },
  {
    path: '/meters',
    name: 'meters',
    component: () => import('@/views/Meters.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['meter-list'],
    },
  },
  {
    path: '/main-meters',
    name: 'main-meters',
    component: () => import('@/views/MainMeters.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['main-meter-list'],
    },
  },
  {
    path: '/meters/:id/more-details',
    name: 'more-meter-details',
    component: () => import('@/views/details/MeterDetails.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['meter-list'],
    },
  },
  {
    path: '/meters/:id',
    name: 'meter-details',
    component: () => import('@/views/Meters.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['meter-list'],
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/Customers.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['user-list'],
    },
  },
  {
    path: '/customers/:id/more-details',
    name: 'more-customer-details',
    component: () => import('@/views/details/CustomerDetails.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['user-list'],
    },
  },
  {
    path: '/customers/:id',
    name: 'customer-details',
    component: () => import('@/views/Customers.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['user-list'],
    },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/Transactions.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['mpesa-transaction-list'],
    },
  },
  {
    path: '/transactions/:id',
    name: 'transaction-details',
    component: () => import('@/views/details/TransactionDetails.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['mpesa-transaction-list'],
    },
  },
  {
    path: '/meter-readings',
    name: 'meter-readings',
    component: () => import('@/views/MeterReadings.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['meter-reading-list'],
    },
  },
  {
    path: '/meter-readings/:id',
    name: 'meter-readings-details',
    component: () => import('@/views/details/MeterReadingDetails.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['meter-reading-list'],
    },
  },
  {
    path: '/unread-meters',
    name: 'unread-meters',
    component: () => import('@/views/UnreadMeters.vue'),
    meta: {
      requiresAuth: true,
      permissions: [],
    },
  },
  {
    path: '/meter-tokens',
    name: 'meter-tokens',
    component: () => import('@/views/MeterTokens.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['meter-token-list'],
    },
  },
  {
    path: '/meter-tokens/:id',
    name: 'meter-tokens-details',
    component: () => import('@/views/details/MeterTokenDetails.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['meter-token-list'],
    },
  },
  {
    path: '/monthly-service-charge',
    name: 'monthly-service-charge',
    component: () => import('@/views/MonthlyServiceCharge.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['monthly-service-charge-list'],
    },
  },
  {
    path: '/monthly-service-charge/:id',
    name: 'monthly-service-charge-details',
    component: () => import('@/views/details/MonthlyServiceChargeDetails.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['monthly-service-charge-list'],
    },
  },
  {
    path: '/connection-fee',
    name: 'connection-fee',
    component: () => import('@/views/ConnectionFee.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['connection-fee-list'],
    },
  },
  {
    path: '/connection-fee/:id',
    name: 'connection-fee-details',
    component: () => import('@/views/details/ConnectionFeeDetails.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['connection-fee-list'],
    },
  },
  {
    path: '/sms',
    name: 'sms',
    component: () => import('@/views/Sms.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['sms-list'],
    },
  },
  {
    path: '/statistics/transactions',
    name: 'transaction-statistics',
    component: () => import('@/views/statistics/transactions/Index.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['statistics-list'],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['setting-list'],
    },
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/errors/403.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/errors/404.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
    meta: {
      requiresAuth: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const { isUserAuthenticated } = store.getters
  if (to.name === 'login' && isUserAuthenticated) {
    return router.push({ path: 'dashboard' })
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserAuthenticated) {
      return router.push({ path: 'login' })
    }
    const { permissions } = store.getters.userInfo
    if (to.meta.permissions && to.meta.permissions.length > 0) {
      const isAllowed = permissions.some(p => to.meta.permissions.includes(p))

      if (!isAllowed) {
        return router.push({ path: 'error-403' })
      }
    }

    return next()
  }

  return next()
})

export default router
